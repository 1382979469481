import React, { useState, useEffect, useRef } from "react";
import {
    Filter,
    ChevronDown,
    ChevronUp,
    Search,
    Edit2,
    Plus,
    Layout,
    Store,
    Globe,
    AlertTriangle,
    Ban,
    WifiOff,
    Check,
    RefreshCcw
} from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { createPortal } from "react-dom";
import NewMonitorPopup from "./Popups/NewMonitorPopup";
import NewUpdatePopup from "./Popups/NewUpdatePopup";

interface MonitorData {
    monitor_name: string;
    monitor_uuid: string;
    input_allowed: boolean;
    monitor_type: string;
    product: string;
    product_name: string;
    example_inputs: string[];
    status?: string;
    not_setup: boolean
}

interface MonitorTableProps {
    monitorsPreview: MonitorData[];
    handleEdit: (monitor: MonitorData) => void;
    handleBlur: () => void;
    developer: Boolean;
}

interface FilterOption {
    name: string;
    icon: React.ReactNode;
    developerOnly?: boolean;
}

const MonitorTable: React.FC<MonitorTableProps> = ({ monitorsPreview, handleEdit, developer, handleBlur }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tooltip, setTooltip] = useState<{ content: string; x: number; y: number } | null>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [newMonitorPopupOpen, setNewMonitorPopupOpen] = useState<boolean>(false);
    const [newUpdatePopupOpen, setNewUpdatePopupOpen] = useState<boolean>(false);

    const [filteredMonitors, setFilteredMonitors] = useState<MonitorData[]>(monitorsPreview);
    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const initialFilter = searchParams.get('filter') || 'All';
    const initialSearch = searchParams.get('search') || '';

    const [selectedFilter, setSelectedFilter] = useState<string>(initialFilter);
    const [searchTerm, setSearchTerm] = useState(initialSearch);

    const filterOptions: FilterOption[] = [
        { name: 'All', icon: <Check size={16} className="text-emerald-400" /> },
        { name: 'Alternative', icon: <Globe size={16} className="text-blue-400" /> },
        { name: 'Sneaker', icon: <Layout size={16} className="text-indigo-400" /> },
        { name: 'Not Setup', icon: <AlertTriangle size={16} className="text-yellow-400" /> },
        { name: 'Blocked', icon: <Ban size={16} className="text-red-400" />, developerOnly: true },
        { name: 'Offline', icon: <WifiOff size={16} className="text-slate-400" />, developerOnly: true }
    ];

    const getFilterIcon = (filterName: string) => {
        const option = filterOptions.find(opt => opt.name === filterName);
        return option?.icon || <Filter size={16} className="text-slate-400" />;
    };

    const handleTooltip = (e: React.MouseEvent<Element>, content: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const buttonCenter = rect.left + rect.width / 2;
        setTooltip({
            content,
            x: buttonCenter + window.scrollX,
            y: rect.top + window.scrollY - 5
        });
    };

    const updateURLParams = (filter: string, search: string) => {
        const params: { [key: string]: string } = {};
        if (filter !== 'All') params.filter = filter;
        if (search) params.search = search;
        setSearchParams(params);
    };

    const handleFilterChange = (filter: string) => {
        setSelectedFilter(filter);
        updateURLParams(filter, searchTerm);
        setFilterOpen(false);
    };

    const handleSearchChange = (search: string) => {
        setSearchTerm(search);
        updateURLParams(selectedFilter, search);
    };

    const toggleNewMonitorPopup = () => {
        setNewMonitorPopupOpen(!newMonitorPopupOpen);
    }
    const toggleNewUpdatePopup = () => {
        setNewUpdatePopupOpen(!newUpdatePopupOpen);
    }

    useEffect(() => {
        let tempFiltered = monitorsPreview;

        if (selectedFilter !== "All") {
            if (selectedFilter === "Blocked" || selectedFilter === "Offline") {
                tempFiltered = tempFiltered.filter(monitor => monitor.status?.toLowerCase() === selectedFilter.toLowerCase());
            }
            else if (selectedFilter === "Not Setup") {
                tempFiltered = tempFiltered.filter(monitor => monitor.not_setup);
            }
            else {
                tempFiltered = tempFiltered.filter(monitor => monitor.product_name.toLowerCase() === selectedFilter.toLowerCase());
            }
        }

        if (searchTerm !== "") {
            tempFiltered = tempFiltered.filter(monitor =>
                monitor.monitor_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredMonitors(tempFiltered);
    }, [selectedFilter, monitorsPreview, searchTerm]);

    const getStatusColor = (status: string) => {
        switch (status?.toUpperCase()) {
            case 'ACTIVE':
                return 'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20';
            case 'BLOCKED':
                return 'bg-red-500/10 text-red-400 border border-red-500/20';
            default:
                return 'bg-slate-500/10 text-slate-400 border border-slate-500/20';
        }
    };

    return (
        <div className="w-full h-screen flex flex-col bg-[#0f1729] mobile-margin">
            {developer && newMonitorPopupOpen && (
                <div>
                    <NewMonitorPopup handleBlur={handleBlur} setNewMonitorPopupOpen={setNewMonitorPopupOpen} />
                </div>
            )}
            {developer && newUpdatePopupOpen && (
                <div>
                    <NewUpdatePopup handleBlur={handleBlur} setNewUpdatePopupOpen={setNewUpdatePopupOpen} />
                </div>
            )}
            {/* Fixed Header */}
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Monitor Overview</h1>
            </div>
            {developer && (
                <div className="w-full px-4 sm:px-8 flex items-center gap-4">
                    <button
                        onClick={() => { toggleNewMonitorPopup(); handleBlur() }}
                        className="flex items-center gap-2 px-3 py-2 mb-6 bg-[#0f1729] text-slate-300 rounded-lg text-sm border border-slate-700/50 hover:border-slate-600/50 transition-colors"
                    >
                        <Plus size={16} className="text-slate-400" />
                        Create new monitor
                    </button>
                    <button
                        onClick={() => { toggleNewUpdatePopup(); handleBlur() }}
                        className="flex items-center gap-2 px-3 py-2 mb-6 bg-[#0f1729] text-slate-300 rounded-lg text-sm border border-slate-700/50 hover:border-slate-600/50 transition-colors"
                    >
                        <RefreshCcw size={16} className="text-slate-400" />
                        Create new update
                    </button>
                </div>
            )}

            {/* Main Content Container */}
            <div className="flex-1 px-4 sm:px-8 overflow-hidden pb-8">
                <div className="max-w-7xl mx-auto">
                    <div className="bg-[#1a2234] rounded-xl flex flex-col">
                        {/* Search and Filter Section */}
                        <div className="p-4 sm:p-6 border-b border-slate-700/50">
                            <div className="flex flex-col sm:flex-row gap-4">
                                <div className="relative">
                                    <button
                                        ref={filterButtonRef}
                                        onClick={() => setFilterOpen(!filterOpen)}
                                        style={{
                                            minWidth: "200px"
                                        }}
                                        className="flex items-center gap-2 px-3 py-2 bg-[#0f1729] text-slate-300 rounded-lg text-sm border border-slate-700/50 hover:border-slate-600/50 transition-colors w-full sm:w-auto"
                                    >
                                        {getFilterIcon(selectedFilter)}
                                        <span className="flex-1 text-left">{selectedFilter}</span>
                                        {filterOpen ?
                                            <ChevronUp size={16} className="text-slate-400" /> :
                                            <ChevronDown size={16} className="text-slate-400" />
                                        }
                                    </button>

                                    {filterOpen && createPortal(
                                        <div
                                            className="fixed bg-[#0f1729] rounded-lg border border-slate-700/50 shadow-xl py-1 z-50"
                                            style={{
                                                top: filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().bottom + 8 : 0,
                                                left: filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().left : 0,
                                                width: filterButtonRef.current ? filterButtonRef.current.getBoundingClientRect().width : 0,
                                                minWidth: "200px"
                                            }}
                                        >
                                            <div className="fixed inset-0 -z-10" onClick={() => setFilterOpen(false)} />
                                            {filterOptions.map((option) => (
                                                (!option.developerOnly || developer) && (
                                                    <button
                                                        key={option.name}
                                                        onClick={() => handleFilterChange(option.name)}
                                                        className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700/50 text-left transition-colors flex items-center gap-2"
                                                    >
                                                        {option.icon}
                                                        <span>{option.name}</span>
                                                    </button>
                                                )
                                            ))}
                                        </div>,
                                        document.body
                                    )}
                                </div>

                                <div className="relative flex-1 sm:max-w-xs">
                                    <input
                                        type="text"
                                        autoFocus
                                        placeholder="Search monitors..."
                                        value={searchTerm}
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search
                                        size={16}
                                        className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
                                    />
                                </div>
                                <div className="flex items-center justify-center min-w-[80px] text-sm text-slate-400">
                                    {filteredMonitors.length} monitor{filteredMonitors.length !== 1 ? 's' : ''}
                                </div>
                            </div>
                        </div>

                        {/* Table Container */}
                        <div className="flex flex-col">
                            {/* Fixed Table Header */}
                            <table className="w-full border-collapse table-fixed">
                                <thead className="border-b border-slate-700/50">
                                    <tr>
                                        <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center w-1/5">Name</th>
                                        <th className="hidden sm:table-cell py-3 px-4 text-sm font-medium text-slate-400 text-center w-1/5">Product</th>
                                        {developer && (
                                            <>
                                                <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center w-1/5">Type</th>
                                                <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center w-1/5">Status</th>
                                            </>
                                        )}
                                        <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center w-1/5">Actions</th>
                                    </tr>
                                </thead>
                            </table>

                            {/* Scrollable Table Body */}
                            <div className="overflow-y-auto" style={{ maxHeight: "calc(100vh - 320px)" }}>
                                <table className="w-full border-collapse table-fixed">
                                    <tbody>
                                        {filteredMonitors.map((monitor) => (
                                            <tr
                                                key={monitor.monitor_uuid}
                                                className="border-b border-slate-700/50 last:border-b-0 hover:bg-slate-700/20 transition-colors"
                                            >
                                                <td className="py-3 px-4 w-1/5">
                                                    <div className="flex items-center justify-center gap-2">
                                                        <span className="text-sm text-slate-100 font-medium">{monitor.monitor_name}</span>
                                                        {monitor.not_setup && (
                                                            <AlertTriangle
                                                                size={16}
                                                                className="text-yellow-400 cursor-help"
                                                                onMouseEnter={(e) => handleTooltip(e, 'Monitor needs setup')}
                                                                onMouseLeave={() => setTooltip(null)}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="hidden sm:table-cell py-3 px-4 w-1/5">
                                                    <div className="flex items-center justify-center">
                                                        <span className="text-sm text-slate-300">{monitor.product_name}</span>
                                                    </div>
                                                </td>
                                                {developer && (
                                                    <>
                                                        <td className="py-3 px-4 w-1/5">
                                                            <div className="flex items-center justify-center">
                                                                <span className="text-sm text-slate-300">{monitor.monitor_type}</span>
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-4 w-1/5">
                                                            <div className="flex items-center justify-center">
                                                                <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(monitor.status || '')}`}>
                                                                    {monitor.status}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </>
                                                )}
                                                <td className="py-3 px-4 w-1/5">
                                                    <div className="flex items-center justify-center">
                                                        <button
                                                            onClick={() => handleEdit(monitor)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Edit2
                                                                size={16}
                                                                className="text-slate-400 group-hover:text-blue-400"
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {filteredMonitors.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={developer ? 5 : 3}
                                                    className="py-4 px-4 text-center text-sm text-slate-400"
                                                >
                                                    No monitors found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tooltip */}
            {tooltip && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 px-3 py-1.5 bg-[#1a2234] text-sm text-slate-100 rounded-lg shadow-xl border border-slate-700/50"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)',
                        pointerEvents: 'none',
                    }}
                >
                    {tooltip.content}
                    <div
                        className="absolute left-1/2 bottom-0 w-2 h-2 bg-[#1a2234] border-r border-b border-slate-700/50 -translate-x-1/2 translate-y-1/2 rotate-45"
                    />
                </div>
            )}
        </div>
    );
};

export default MonitorTable;