import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, Plus, X } from 'lucide-react';
import { Monitor } from '../../../../types';
import axios from 'axios';
import { API_URL } from '../../../../constants';
import { toast, Id } from 'react-toastify';
import AddUserPopup from '../Popups/AddCustomerPopup';
import ReactDOM from 'react-dom';


interface MonitorDetailsSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: any) => void;
    setIsLoading: (loading: boolean) => void;
    handleBlur: () => void;

}

const MonitorDetailsSection: React.FC<MonitorDetailsSectionProps> = ({
    currentMonitor,
    setIsLoading,
    setCurrentMonitor,
    handleBlur,
}) => {
    const [monitorDelay, setMonitorDelay] = useState<number>(currentMonitor.monitorSettings?.delay ?? 10);
    const [selectedProxyList, setSelectedProxyList] = useState<{ id: number; name: string }>(
        currentMonitor.monitorSettings?.selectedProxyList ?? { id: 0, name: '' }
    );
    const [selectedProduct, setSelectedProduct] = useState<{ id: number; name: string }>(
        currentMonitor.monitorSettings?.selectedProduct ?? { id: 0, name: '' }
    );
    const [selectedKeywordList, setSelectedKeywordList] = useState<{ id: number; name: string }>(
        currentMonitor.monitorSettings?.selectedKeywordList ?? { id: 0, name: '' }
    );
    const [inputsAllowed, setInputsAllowed] = useState<boolean>(currentMonitor.data?.input_allowed ?? false);
    const [newExampleInput, setNewExampleInput] = useState('');
    const [exampleInputs, setExampleInputs] = useState<string[]>(currentMonitor.data?.example_inputs ?? []);
    const [editingExampleInputIndex, setEditingExampleInputIndex] = useState<number | null>(null);
    const toastIdRef = useRef<Id | null>(null);
    const [addUserPopupOpen, setAddUserPopupOpen] = useState(false);

    const [delayInputValue, setDelayInputValue] = useState<string>(currentMonitor.monitorSettings?.delay?.toString() ?? '10');


    const showToast = (message: string, type: "info" | "success" | "error") => {
        if (toastIdRef.current) {
            toast.dismiss(toastIdRef.current);
        }

        if (type === "info") {
            toastIdRef.current = toast.loading(message);
        } else {
            toastIdRef.current = toast[type](message, {
                autoClose: 3000,
                onClose: () => {
                    toastIdRef.current = null;
                }
            });
        }
    };


    const handleDelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDelayInputValue(value); // Update the input value immediately
    };
    const handleDelaySubmit = () => {
        const numericValue = parseInt(delayInputValue, 10);
        if (!isNaN(numericValue)) {
            setMonitorDelay(numericValue); // This will trigger the update effect
        } else {
            // Reset to previous valid value if input is invalid
            setDelayInputValue(monitorDelay.toString());
        }
    };

    const handleProxyListChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        const selected = currentMonitor.monitorSettings?.proxyLists?.[selectedId];
        if (selected) {
            setSelectedProxyList({ id: selectedId, name: selected.name });
        }
    };
    const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        const selected = currentMonitor.monitorSettings?.products?.[selectedId];
        if (selected) {
            setSelectedProduct({ id: selectedId, name: selected.name });
        }
    };

    const handleKeywordListChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        const selected = currentMonitor.monitorSettings?.keywordLists?.[selectedId];
        if (selected) {
            setSelectedKeywordList({ id: selectedId, name: selected.name });
        }
    };

    const handleInputsAllowedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value === "true";
        setInputsAllowed(newValue);
    };

    const handleAddExampleInput = () => {
        if (newExampleInput.trim()) {
            setExampleInputs(prevInputs => [...prevInputs, newExampleInput.trim()]);
            setNewExampleInput('');
        }
    };

    const handleRemoveExampleInput = (index: number) => {
        setExampleInputs(prevInputs => prevInputs.filter((_, i) => i !== index));
    };

    // Update example inputs
    useEffect(() => {
        const updateExampleInputs = async () => {
            if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
            }

            toastIdRef.current = toast.loading("Updating example inputs...");

            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/example-inputs`,
                    { example_inputs: exampleInputs },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    toast.dismiss(toastIdRef.current);
                    toastIdRef.current = toast.success("Example inputs updated successfully", {
                        autoClose: 3000,
                        onClose: () => {
                            toastIdRef.current = null;
                        }
                    });
                }
            } catch (error) {
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = toast.error("Failed to update example inputs", {
                    autoClose: 3000,
                    onClose: () => {
                        toastIdRef.current = null;
                    }
                });
                console.error('Failed to update example inputs', error);
            }
        };

        if (JSON.stringify(exampleInputs) !== JSON.stringify(currentMonitor.data.example_inputs)) {
            updateExampleInputs();
        }
    }, [exampleInputs, currentMonitor.data.monitor_uuid]);

    // Update delay
    useEffect(() => {
        const updateDelay = async () => {
            if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
            }
            const updatedMonitor = {
                ...currentMonitor,
                monitorSettings: {
                    ...currentMonitor.monitorSettings,
                    delay: monitorDelay || 3,
                },
            };
            setCurrentMonitor(updatedMonitor);

            toastIdRef.current = toast.loading("Updating delay...");

            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`,
                    {
                        delay: monitorDelay,
                        type: "delay"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {


                    toast.dismiss(toastIdRef.current);
                    toastIdRef.current = toast.success("Successfully updated delay", {
                        autoClose: 3000,
                        onClose: () => {
                            toastIdRef.current = null;
                        }
                    });
                }
            } catch (error) {
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = toast.error("Failed to update delay", {
                    autoClose: 3000,
                    onClose: () => {
                        toastIdRef.current = null;
                    }
                });
                console.error('Failed to update delay', error);
            }
        };

        if (currentMonitor.monitorSettings && monitorDelay !== currentMonitor.monitorSettings.delay) {
            updateDelay();
        }
    }, [monitorDelay, currentMonitor.data.monitor_uuid]);

    // Update proxylist
    useEffect(() => {
        const updateProxyList = async () => {
            if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
            }

            toastIdRef.current = toast.loading("Updating proxy list...");

            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`,
                    {
                        proxyListId: selectedProxyList.id,
                        type: "proxyList"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    const updatedMonitor = {
                        ...currentMonitor,
                        monitorSettings: {
                            ...currentMonitor.monitorSettings,
                            selectedProxyList: selectedProxyList,
                            delay: currentMonitor.monitorSettings?.delay || 3,
                        },
                    };
                    setCurrentMonitor(updatedMonitor);

                    toast.dismiss(toastIdRef.current);
                    toastIdRef.current = toast.success("Proxy list updated successfully", {
                        autoClose: 3000,
                        onClose: () => {
                            toastIdRef.current = null;
                        }
                    });
                }
            } catch (error) {
                toast.dismiss(toastIdRef.current);
                toastIdRef.current = toast.error("Failed to update proxy list", {
                    autoClose: 3000,
                    onClose: () => {
                        toastIdRef.current = null;
                    }
                });
                console.error('Failed to update proxyList', error);
            }
        };

        if (currentMonitor.monitorSettings?.selectedProxyList &&
            selectedProxyList.id !== currentMonitor.monitorSettings.selectedProxyList.id) {
            updateProxyList();
        }
    }, [selectedProxyList.id, currentMonitor.data.monitor_uuid]);

    // Update Product
    useEffect(() => {
        const updateProduct = async () => {
            showToast("Updating product...", "info");
            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`,
                    {
                        productId: selectedProduct.id,
                        type: "product"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    showToast("Product updated successfully", "success");
                    // update currentMonitor
                    const updatedMonitor = {
                        ...currentMonitor,
                        monitorSettings: {
                            ...currentMonitor.monitorSettings,
                            selectedProduct: selectedProduct,
                        },
                    };

                    setCurrentMonitor(updatedMonitor);

                }
            } catch (error) {
                console.error('Failed to update product', error);
                showToast("Failed to update product", "error");
            }
        };

        if (currentMonitor.monitorSettings?.selectedProduct &&
            selectedProduct.id !== currentMonitor.monitorSettings.selectedProduct.id) {
            updateProduct();
        }
    }, [selectedProduct.id, currentMonitor.data.monitor_uuid]);

    // Update keywords
    useEffect(() => {
        const updateKeywordList = async () => {
            showToast("Updating keywords...", "info");
            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`,
                    {
                        keywordID: selectedKeywordList.id,
                        type: "keywords"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    showToast("Keywords updated successfully", "success");
                    const updatedMonitor = {
                        ...currentMonitor,
                        monitorSettings: {
                            ...currentMonitor.monitorSettings,
                            selectedKeywordList: selectedKeywordList,
                        },
                    };

                    setCurrentMonitor(updatedMonitor);
                }
            } catch (error) {
                console.error('Failed to update keywords', error);
                showToast("Failed to update keywords", "error");
            }
        };

        if (currentMonitor.monitorSettings?.selectedKeywordList &&
            selectedKeywordList.id !== currentMonitor.monitorSettings.selectedKeywordList.id) {
            updateKeywordList();
        }
    }, [selectedKeywordList.id, currentMonitor.data.monitor_uuid]);

    // Update inputs allowed
    useEffect(() => {
        const updateIputsAllowed = async () => {
            showToast("Updating inputs allowed...", "info");
            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`,
                    {
                        inputsAllowed: inputsAllowed,
                        type: "inputs"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    showToast("Inputs allowed updated successfully", "success");
                    // update currentMonitor
                    const updatedMonitor = {
                        ...currentMonitor,
                        data: {
                            ...currentMonitor.data,
                            input_allowed: inputsAllowed
                        }

                    };

                    setCurrentMonitor(updatedMonitor);

                }
            } catch (error) {
                showToast("Failed to update inputs allowed settings", "error");
            }
        };
        if (inputsAllowed !== currentMonitor.data.input_allowed) {
            updateIputsAllowed();
        }


    }, [inputsAllowed]);

    return (
        <div className="bg-[#1a2234] rounded-xl overflow-hidden">
            <div className="p-4 sm:p-6">
                <h3 className="text-lg font-medium text-slate-100 mb-6">Monitor Details</h3>

                <div className="space-y-4">

                    <button
                        onClick={() => { setAddUserPopupOpen(true); handleBlur() }}
                        className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                    >
                        <Plus size={16} />
                        <span>Add User</span>
                    </button>
                    {addUserPopupOpen && (
                        <AddUserPopup
                            monitorUuid={currentMonitor.data.monitor_uuid}
                            onClose={() => { setAddUserPopupOpen(false); handleBlur() }}
                            handleBlur={handleBlur}
                        />
                    )}

                    <div>
                        <label className="block text-sm text-slate-400 mb-1">Delay</label>
                        <input
                            type="text"
                            value={delayInputValue}
                            onChange={handleDelayChange}
                            onBlur={handleDelaySubmit}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.currentTarget.blur(); // This will trigger onBlur
                                }
                            }}
                            className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                        />
                    </div>

                    <div>
                        <label className="block text-sm text-slate-400 mb-1">Inputs Allowed</label>
                        <div className="relative">
                            <select
                                value={inputsAllowed.toString()}
                                onChange={handleInputsAllowedChange}
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                            >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                            </select>
                            <ChevronDown
                                size={16}
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm text-slate-400 mb-1">Proxy List</label>
                        <div className="relative">
                            <select
                                value={selectedProxyList.id.toString()}
                                onChange={handleProxyListChange}
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                            >
                                {Object.entries(currentMonitor.monitorSettings?.proxyLists ?? {}).map(([id, list]) => (
                                    <option key={id} value={id}>{list.name}</option>
                                ))}
                            </select>
                            <ChevronDown
                                size={16}
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm text-slate-400 mb-1">Product</label>
                        <div className="relative">
                            <select
                                value={selectedProduct.id.toString()}
                                onChange={handleProductChange}
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                            >
                                {Object.entries(currentMonitor.monitorSettings?.products ?? {}).map(([id, list]) => (
                                    <option key={id} value={id}>{list.name}</option>
                                ))}
                            </select>
                            <ChevronDown
                                size={16}
                                className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                            />
                        </div>
                    </div>

                    {currentMonitor.data.monitor_type === "new" && (
                        <div>
                            <label className="block text-sm text-slate-400 mb-1">Keywords</label>
                            <div className="relative">
                                <select
                                    value={selectedKeywordList.id.toString()}
                                    onChange={handleKeywordListChange}
                                    className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                                >
                                    {Object.entries(currentMonitor.monitorSettings?.keywordLists ?? {}).map(([id, list]) => (
                                        <option key={id} value={id}>{list.name}</option>
                                    ))}
                                </select>
                                <ChevronDown
                                    size={16}
                                    className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                                />
                            </div>
                        </div>
                    )}

                    <div className="space-y-4">
                        <label className="block text-sm text-slate-400">Example Inputs</label>
                        <div className="flex gap-2">
                            <input
                                type="text"
                                value={newExampleInput}
                                onChange={(e) => setNewExampleInput(e.target.value)}
                                placeholder="Enter new example input"
                                className="flex-1 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                            />
                            <button
                                onClick={handleAddExampleInput}
                                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                            >
                                <Plus size={16} />
                                <span>Add</span>
                            </button>
                        </div>

                        <div className="space-y-2">
                            {exampleInputs.map((input, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between p-2 bg-[#0f1729] rounded-lg border border-slate-700/50"
                                >
                                    {editingExampleInputIndex === index ? (
                                        <input
                                            type="text"
                                            value={input}
                                            onChange={(e) => {
                                                const newInputs = [...exampleInputs];
                                                newInputs[index] = e.target.value;
                                                setExampleInputs(newInputs);
                                            }}
                                            onBlur={() => setEditingExampleInputIndex(null)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setEditingExampleInputIndex(null);
                                                }
                                            }}
                                            className="flex-1 bg-[#0f1729] text-slate-100 text-sm px-2 focus:outline-none"
                                            autoFocus
                                        />
                                    ) : (
                                        <span
                                            onClick={() => setEditingExampleInputIndex(index)}
                                            className="flex-1 text-sm text-slate-300 cursor-pointer"
                                        >
                                            {input}
                                        </span>
                                    )}
                                    <button
                                        onClick={() => handleRemoveExampleInput(index)}
                                        className="p-1 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                    >
                                        <X size={16} className="text-slate-400 group-hover:text-red-400" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitorDetailsSection;